.loading {
  font-size: 1.5rem;
  height: 100vh;
  box-sizing: border-box;
  width: 100vw;
  color: white;
  padding: 2.5rem;

  p {
    margin: 0 0 1rem 0;
  }
}
