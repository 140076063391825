@font-face {
  font-family: "DOS";
  src: url("../fonts/perfectDOS.ttf");
}
@import "./breakpoint.scss";
@import "./stages/loading.scss";
@import "./stages/splash.scss";
@import "./stages/selectscreen.scss";
@import "./stages/video.scss";
@import "./components/footer.scss";

html {
  font-size: 16px;

  @include media("<=927px") {
    font-size: 10px;
  }
  @include media("<=816px") {
    font-size: 8px;
  }
}

body {
  font-family: "DOS";
  background-color: #232323;
  cursor: url("../assets/arrow2.svg") pointer;
}

.container {
  width: 100vw;
  box-sizing: border-box;
  height: 100vh;
  padding: 2.5rem;
  position: relative;
}

.rotation {
  p {
    font-size: 2rem;
    color: white;
    text-align: center;
  }
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.particles {
  z-index: -1;
  .tsparticles-canvas-el {
    z-index: -1 !important;
  }
}

.game-wrapper {
  z-index: 10;
  width: 100%;
  pointer-events: all;
  height: 100%;
  position: absolute;
  overflow: hidden;
}

button {
  font-size: 1.5rem;
  border-style: none;
  font-family: "DOS";
  padding: 1rem 2rem;
  color: white;
  background: #ef5f5f;
  box-shadow: 0.5rem 0.5rem 0px rgba(243, 37, 37, 0.25);

  &:hover {
    background: #ae3f3f;
    transform: translateX(-3px) translateY(-3px);
  }
}
