.select-screen {
  width: 50vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  color: white;
  h2 {
    margin: 0;
    font-size: 1.5rem;
  }
  .select-character {
    font-size: 2rem;
    font-weight: 100;
    text-align: center;
    margin-bottom: 1rem;
  }
  .character-grid {
    margin: 2rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    gap: 3rem;
    .character {
      width: 6rem;
      height: 6rem;
      position: relative;
      box-shadow: 0.75rem 0.75rem 0px rgba(0, 0, 0, 0.25);
      transition: box-shadow 0.2s ease-in;
      background-color: #242424;
      &:hover {
      }

      .selected {
        height: 6rem;
        width: 6rem;
        position: absolute;
        border: 0.35rem solid #26c552;
      }
      .character-image {
        background-color: #242424;

        border: 0.35rem solid #3b5c45;
        overflow: hidden;
        width: 100%;
        height: 100%;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
