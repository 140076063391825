footer {
  z-index: 400;
  //display: none;

  color: white;
  font-size: 0.75rem;
  a {
    position: absolute;
    right: 2rem;
    bottom: 0.5rem;
    color: white;
    text-decoration: none;
    transition: text-decoration 0.3s ease-in-out;
    &:hover {
      text-decoration: underline;
    }

    img {
      width: 16rem;
    }
  }
  button {
    position: absolute;
    left: 2rem;
    z-index: 1000;
    bottom: 2rem;
    font-size: 1rem;
    border-style: none;
    font-family: "DOS";
    padding: 0.5rem 1rem;
    color: white;
    background: #ef5f5f;
    box-shadow: 6px 6px 0px rgba(243, 37, 37, 0.25);

    &:hover {
      background: #ae3f3f;
      transform: translateX(-3px) translateY(-3px);
    }
  }
}
