.video {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  color: white;

  .video-wrapper {
    height: 70vh;
    aspect-ratio: 16/9;

    height: 74vh;
    width: 64vw;

    @include media("<=phone") {
      height: 60vh;
      width: 64vw;
    }
    background-color: black;
    border: 0.25rem solid #3b5c45;
    box-sizing: border-box;
    box-shadow: 0.5rem 0.5rem 0px rgba(0, 0, 0, 0.25);
    position: relative;
    h2 {
      margin: 0;
    }

    .loading {
      width: 100%;
      height: 100%;
      position: relative;
      h2 {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
      }
    }
  }
}

.modal-container {
  width: 60vw;
  color: white;
  height: 60vh;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background-color: #333333;
  border: 0.25rem solid #ef5f5f;
  box-shadow: 0.5rem 0.5rem 0px rgba(0, 0, 0, 0.25);

  box-sizing: border-box;
  text-align: center;
  align-items: center;

  .modal-wrapper {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    h2 {
      font-size: 2rem;
      line-height: 2rem;
      text-decoration-line: underline;
      margin-bottom: 2rem;
    }

    h3 {
      font-size: 1.5rem;
      line-height: 2rem;
      margin-bottom: 4rem;
    }
  }
}
