.splash {
  height: 50vh;
  aspect-ratio: 16/9;
  // width: 60vw;

  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  z-index: 100;
  .splash-image {
    border: 0.25rem solid #3b5c45;
    box-shadow: 0.5rem 0.5rem 0px rgba(0, 0, 0, 0.25);
    height: 100%;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
